import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const StorePage = () => {

    return(
        <Layout pageTitle="Coming Soon!">
            <SEO title="Home" keywords={[`LOnWheels`, `Skates`, `RollerSkates`, `CandiGirl`,`gatsby`,`application`,`react`]} />
            
        </Layout>
    )
}

export default StorePage